import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Footer } from '../components/Layout'
import { ArticleHeader, Section, Article, Dinkus } from '../components/Article'

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        author
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }
`

const Post = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description}
      author={frontmatter.author}
      type="article"
      image={frontmatter.image}
    >
      <Article>
        <ArticleHeader
          title={frontmatter.title}
          subtitle={`av ${frontmatter.author} ${new Date(
            frontmatter.date
          ).toLocaleDateString('sv')}`}
        />
        <Section>
          <div>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
            <Dinkus>* * *</Dinkus>
            <footer>
              <p>
                Sciigo Sustainability Intelligence är en plattform för att samla
                och strukturera hållbarhetsdata för fastigheter, samt rapportera
                till styrelse och kreditgivare.{' '}
              </p>
              <p>
                Läs mer på <a href="/">sciigo.com</a> eller{' '}
                <a href="mailto:hello@sciigo.io">hör av dig</a>!
              </p>
            </footer>
          </div>
        </Section>
        <Footer>
          <p>
            <a href="/">sciigo.com</a>
          </p>
          <p>
            <a href="mailto:hello@sciigo.com">hello@sciigo.com</a>
          </p>
        </Footer>
      </Article>
    </Layout>
  )
}

export default Post
